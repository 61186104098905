import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import { abi as OlympusStakingv2 } from "../abi/OlympusStakingv2.json";
import { abi as sOHMv2 } from "../abi/sOhmv2.json";
import { setAll, getTokenPrice, getMarketPrice } from "../helpers";
import { NodeHelper } from "../helpers/NodeHelper";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as ReserveOhmLusdContract } from "src/abi/reserves/OhmLusd.json";
import { abi as TSContract } from "src/abi/treas.json";
import { abi as DSContract } from "src/abi/DistributorContract.json";
import allBonds, { allBondsMap } from "src/helpers/AllBonds";
import { useSelector } from "react-redux";
import { Decimal } from "decimal.js"
import { NetworkID, } from "src/lib/Bond";
import { abi as wsOHM } from "../abi/wsOHM.json";
import { abi as vyABI } from "../abi/governance/vy.json";
import { abi as stake } from "../abi/governance/stake.json";
const initialState = {
  loading: false,
  loadingMarketPrice: false,
};

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider);
    const totalSupply = Number(await ohmContract.totalSupply());
    const StakeBalance = Number(await ohmContract.balanceOf(addresses[networkID].STAKING_ADDRESS));
    const Price = await getMarketPrice({ networkID, provider });
    const stakingTVL = StakeBalance * Price / (Math.pow(10, 6));
    const Pledgerate = StakeBalance / totalSupply;
    let marketPrice;
    try {
      const originalPromiseResult = await dispatch(
        loadMarketPrice({ networkID: networkID, provider: provider }),
      ).unwrap();
      marketPrice = originalPromiseResult?.marketPrice;
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.error("Returned a null response from dispatch(loadMarketPrice)");
      return;
    }
    const marketCap = marketPrice * totalSupply / (Math.pow(10, 9));
    const circSupply = 0;

    // const currentBlock = parseFloat(graphData.data._meta.block.number);

    if (!provider) {
      console.error("failed to connect to provider, please connect your wallet");
      return {
        stakingTVL,
        marketPrice,
        marketCap,
        circSupply,
        totalSupply,
      };
    }
    const currentBlock = await provider.getBlockNumber();
    const stakingContract = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingv2,
      provider,
    );
    const warmupPeriod = await stakingContract.warmupPeriod()
    const lpContract = new ethers.Contract(addresses[networkID].LP_ADDRESS as string, ReserveOhmLusdContract, provider)
    const treasuryLp = await lpContract.balanceOf(addresses[networkID].TREASURY_ADDRESS);
    const lpTotal = await lpContract.totalSupply()
    const lpBal = Number(await ohmContract.balanceOf(addresses[networkID].LP_ADDRESS));
    const daoBal = Number(await ohmContract.balanceOf(addresses[networkID].DAO_ADDRESS));
    const sohmMainContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider);
    // Calculating staking
    const epoch = await stakingContract.epoch();
    const stakingReward = epoch.distribute;
    const endBlock = epoch.endBlock;
    const circ = await sohmMainContract.circulatingSupply();
    const stakingRebase = stakingReward / circ;
    const oneDayRate = Math.pow(1 + stakingRebase, 3) - 1;
    const fiveDayRate = Math.pow(1 + stakingRebase, 5 * 3) - 1;
    // const stakingAPY1 = Math.pow(1 + stakingRebase, 365 * 3) - 1;
    const stakingAPY = (new Decimal("1").add(new Decimal(stakingRebase))).pow(365 * 3).sub(new Decimal("1"))
    // Current index
    const currentIndex = await stakingContract.index();

    const tsContract = new ethers.Contract(addresses[networkID].TREASURY_ADDRESS as string, TSContract, provider);
    const treasuryReBalance = await tsContract.totalReserves();
    const disContract = new ethers.Contract(addresses[networkID].DISTRIBUTOR_ADDRESS as string, DSContract, provider)

    const StakeRate = (await disContract.info(0)).rate //TODO  1
    const runWay = (Math.log(treasuryReBalance / StakeBalance) / Math.log(1 + StakeRate / Math.pow(10, 6))) / 3
    let allBondBal = Number(0);
    for (let i = 0; i < allBonds.length; i++) {
      let bondAddr = allBonds[i].getAddressForBond(networkID)
      allBondBal += Number(await ohmContract.balanceOf(bondAddr));
    }
    const aSOHMMigrationBal = Number(await ohmContract.balanceOf(addresses[networkID].ASOHM_MIGRATION_ADDRESS));
    const TsVar = daoBal + allBondBal + ((treasuryLp / lpTotal) * lpBal) + StakeBalance + aSOHMMigrationBal
    const luckRate = TsVar / totalSupply;
    const TotalLuckPrice = TsVar * marketPrice / Math.pow(10, 9)

    const wsohmContract = new ethers.Contract(addresses[networkID].WSOHM_ADDRESS as string, wsOHM, provider);
    const wtosBalance = await wsohmContract.wPRIVATETosPRIVATE(ethers.utils.parseUnits('1', 9))
    const stowBalance = await wsohmContract.sPRIVATETowPRIVATE(ethers.utils.parseUnits('1', 9))
    return {
      currentIndex: ethers.utils.formatUnits(currentIndex, "gwei"),
      oneDayRate,
      warmupPeriod,
      currentBlock,
      fiveDayRate,
      stakingAPY,
      stakingTVL,
      endBlock,
      stakingRebase,
      marketCap,
      marketPrice,
      circSupply,
      totalSupply,
      Pledgerate,
      luckRate,
      TotalLuckPrice,
      treasuryReBalance,
      runWay,
      daoBal,
      wtosBalance,
      stowBalance,
    } as IAppData;
  },
);

export const loadGovernanceDetails = createAsyncThunk(
  "app/loadGovernanceDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {

    const wsohmContract = new ethers.Contract(addresses[networkID].WSOHM_ADDRESS as string, wsOHM, provider);
    const lockedWPRIVATE = await wsohmContract.balanceOf(addresses[networkID].vy)//TODO:
    const lockedPRIVATE = await wsohmContract.wPRIVATETosPRIVATE(lockedWPRIVATE)
    const privateContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider);
    const privatetotalSupply = Number(await privateContract.totalSupply());
    const cicrPRIVATELocked = lockedPRIVATE / privatetotalSupply;
    const stakeContract = new ethers.Contract(addresses[networkID].stake as string, stake, provider);
    const rewardAmount = await stakeContract.rewardAmount()
    const stakeBalance = await privateContract.balanceOf(addresses[networkID].stake)
    const unallocatedReward = Number(stakeBalance) - Number(rewardAmount)
    const yieldForDuration = await stakeContract.getYieldForDuration()
    const vyContract = new ethers.Contract(addresses[networkID].vy as string, vyABI, provider)
    const avgLockTime = ((Number(await vyContract.totalSupply()) + Number(await vyContract.supply())) / lockedWPRIVATE - 1) / 3 * 1460
    const apr = yieldForDuration / lockedPRIVATE * 365 / 7
    return {
      lockedPRIVATE: ethers.utils.formatUnits(lockedPRIVATE, "gwei"),
      cicrPRIVATELocked,
      unallocatedReward: unallocatedReward / Math.pow(10, 9),
      yieldForDuration: ethers.utils.formatUnits(yieldForDuration, "gwei"),
      apr,
      avgLockTime
    };
  },
);

/**
 * checks if app.slice has marketPrice already
 * if yes then simply load that state
 * if no then fetches via `loadMarketPrice`
 *
 * `usage`:
 * ```
 * const originalPromiseResult = await dispatch(
 *    findOrLoadMarketPrice({ networkID: networkID, provider: provider }),
 *  ).unwrap();
 * originalPromiseResult?.whateverValue;
 * ```
 */
export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadingMarketPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);

/**
 * - fetches the OHM price from CoinGecko (via getTokenPrice)
 * - falls back to fetch marketPrice from ohm-dai contract
 * - updates the App.slice when it runs
 */
const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  try {
    marketPrice = await getMarketPrice({ networkID, provider });
    marketPrice = marketPrice * Math.pow(10, 3);
  } catch (e) {
    marketPrice = await getTokenPrice();
  }
  return { marketPrice };
});

interface IAppData {
  readonly circSupply: number;
  readonly currentIndex?: string;
  readonly currentBlock?: number;
  readonly fiveDayRate?: number;
  readonly marketCap: number;
  readonly marketPrice: number;
  readonly stakingAPY?: any;
  readonly stakingRebase?: number;
  readonly stakingTVL: number;
  readonly totalSupply: number;
  readonly treasuryLpance?: number;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(loadGovernanceDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadGovernanceDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadGovernanceDetails.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
